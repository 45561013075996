<script setup>
  import { useSession, route } from '@plenny/visitor';
  import IconPersonStroke from '../icons/IconPersonStroke.vue';

  const session = useSession();

</script>
<template>
  <div class="actions">
    <template v-if="!session.is_authenticated">
      <VisitorLink :href="route('web.account.session.create')" class="actions__item actions__item-sign-in" primary>
        <IconPersonStroke />
        {{ $t('Zaloguj się') }}
      </VisitorLink>
      |
      <VisitorLink :href="route('web.account.register.create')" class="actions__item">
        {{ $t('Zarejestruj się') }}
      </VisitorLink>
    </template>
    <template v-else>
      <VisitorLink :href="route('web.account.show')" class="actions__item">
        <IconPersonCircle />
        {{ $t('Konto') }}
      </VisitorLink>
    </template>
  </div>
</template>
<style lang="scss" scoped>
  .actions {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &__item-sign-in {
      font-weight: var(--font-weight-bold);
      display: inline-flex;
      align-items: center;

      svg {
        font-size: 1.125rem;
      }
    }

    &__item {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
</style>
