<script setup lang="ts">
  import { ref } from 'vue';

  defineOptions({
    inheritAttrs: false,
  });

  defineProps({
    header: String,
    header_type: String,
    posts: Array,
  });


  const parameters = ref({
    navigation: true,
    breakpoints: {
      1: {
        slidesPerView: 1.25,
        slidesOffsetBefore: 16,
        spaceBetween: 16,
      },
      992: {
        slidesPerView: 2,
        slidesOffsetBefore: 0,
        spaceBetween: 32,
      },
    },
  });
</script>
<template>
  <Segment :title="header" :headerType="header_type" :link="route('web.blog.post.index')">
    <SfArticlesCarousel :posts="posts" :parameters />
  </Segment>
</template>

