<script setup lang="ts">
  import { ref } from 'vue';

  defineOptions({
    inheritAttrs: false,
  });

  defineProps({
    header: String,
    products: { type: Array, required: true },
    header_type: String,
    link: String,
  });

  const parameters = ref({
    breakpoints: {
      1: {
        slidesPerView: 1.5,
        slidesOffsetBefore: 16,
        spaceBetween: 20,
      },
      576: {
        slidesPerView: 2.5,
        slidesOffsetBefore: 16,
        spaceBetween: 20,
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 32,
        slidesOffsetBefore: 0,
      },
      1200: {
        slidesPerView: 4,
      },
    },
  });
</script>
<template>
  <Segment :title="header" :headerType="header_type" :link>
    <SfProductsCarousel v-if="products.length > 0" :products :parameters />
  </Segment>
</template>