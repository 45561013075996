import { createSSRApp, h } from 'vue';
import { SettingsRegistry } from '$settings';
import { TranslatorPlugin } from '@plenny/translator';
import { PicturePlugin } from '@plenny/picture';
import { VisitorPlugin } from '@plenny/visitor';
import { Storefront, StoreLayout } from '$components';
import { Views } from '$views';
import { GoogleTagManager, CookieConsent } from '$storefront';

export function createStorefront({ router, props }) {
  const app = createSSRApp({ render: () => h(router, props) });

  app.use(GoogleTagManager);
  app.use(CookieConsent);
  app.use(SettingsRegistry);
  app.use(TranslatorPlugin);
  app.use(PicturePlugin);
  app.use(VisitorPlugin);
  app.use(Storefront);

  return app;
}

export async function createViewResolver(name: string) {
  const component = Views[name] as any;

  if (!component) {
    throw new Error('Component ' + name + ' not found!');
  }

  if (component.layout === undefined) {
    component.layout = StoreLayout;
  }

  return component;
}
