import type { App } from 'vue';

interface Configuration {
  id: string,
  name: 'dataLayer' | string;
}

interface Instance {
  id: string,
  name: string,
  event: (...args: any[]) => void,
}

declare global {
  const APP_GOOGLE_TAGS: Configuration[];
}

const CONSENT_MODE_DEFAULTS = {
  ad_storage: 'denied',
  ad_user_data: 'denied',
  ad_personalization: 'denied',
  analytics_storage: 'denied',
  functionality_storage: 'denied',
  personalization_storage: 'denied',
  security_storage: 'denied',
};

const instances: Instance[] = [];

function start(config: Configuration) {
  let { id, name } = config;

  window[name] = window[name] || [];

  function event(...args: any[]) {
    window[name].push(args);
  }

  const instance = { id, name, event };

  event('consent', 'default', CONSENT_MODE_DEFAULTS);
  event('js', new Date());
  event('config', id);

  let element = document.getElementsByTagName('script').item(0)!;
  let script = document.createElement('script');

  script.src = 'https://www.googletagmanager.com/gtag/js?id=' + id + (name != 'dataLayer' ? '&' + name : '');
  script.async = true;

  element.after(script);
  instances.push(instance);
}

export function gtag(...args) {
  if (typeof window !== 'undefined') {
    instances.forEach(({ event }) => event(...args));
  }
}

export const GoogleTagManager = {
  install(app: App) {
    app.config.globalProperties.$gtag = gtag;

    if (typeof window !== 'undefined') {
      for (let config of APP_GOOGLE_TAGS) {
        start(config);
      }
    }
  },
};

declare module 'vue' {
  interface ComponentCustomProperties {
    $gtag: typeof gtag;
  }
}
