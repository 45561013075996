import type { Translation } from 'vanilla-cookieconsent';
import { route } from '@plenny/visitor';

export default function createEnglishTranslation(): Translation {
  return {
    consentModal: {
      title: 'Cookies',
      description: 'We use cookies on our website to give you the most relevant experience by keeping your preferences between your visits. By clicking "Accept all", you consent to the use of all cookies. However, you may visit <a href="javascript:void(0)" data-cc="show-preferencesModal" class="cc-link">Settings</a> to provide controlled consent.',
      acceptAllBtn: 'Accept all',
      acceptNecessaryBtn: 'Reject all',
      showPreferencesBtn: 'Manage preferences',
    },
    preferencesModal: {
      title: 'Cookie preferences',
      acceptAllBtn: 'Accept all',
      acceptNecessaryBtn: 'Reject all',
      savePreferencesBtn: 'Save preferences',
      closeIconLabel: 'Close',
      sections: [
        {
          title: 'Cookie usage',
          description: 'We use cookies to ensure the basic functionalities of the website and to enhance your online experience.',
        },
        {
          title: 'Strictly necessary cookies',
          description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly.',
          linkedCategory: 'necessary',
        },
        {
          title: 'Performance and Analytics',
          description: 'These cookies allow the website to remember the choices you have made in the past.',
          linkedCategory: 'analytics',
        },
        {
          title: 'Advertisement and Targeting',
          description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you.',
          linkedCategory: 'targeting',
        },
        {
          title: 'Functionality',
          description: 'These cookies are used to provide you with a more personalized experience and to remember choices you make when using our website. For example, we may use functional cookies to remember your language preferences or login details.',
          linkedCategory: 'functionality',
        },
        {
          title: 'More information',
          description: 'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="' + route('web.contact.index') + '">contact us</a>.',
        },
      ],
    },
  };
}
