import type { CookieConsentConfig, CookieValue } from 'vanilla-cookieconsent';
import * as Cookies from 'vanilla-cookieconsent';
import type { App } from 'vue';
import en from './Localization/en';
import pl from './Localization/pl';
import { gtag } from '../Analytics/GoogleTagManager';

function pushUpdatedConsent(cookie: CookieValue) {
  gtag('consent', 'update', {
    'ad_storage': cookie.categories.includes('targeting') ? 'granted' : 'denied',
    'ad_user_data': cookie.categories.includes('targeting') ? 'granted' : 'denied',
    'ad_personalization': cookie.categories.includes('targeting') ? 'granted' : 'denied',
    'analytics_storage': cookie.categories.includes('analytics') ? 'granted' : 'denied',
    'functionality_storage': cookie.categories.includes('functionality') ? 'granted' : 'denied',
    'personalization_storage': cookie.categories.includes('functionality') ? 'granted' : 'denied',
    'security_storage': cookie.categories.includes('functionality') ? 'granted' : 'denied',
  });
}

function buildDefaultSettings(config: Partial<CookieConsentConfig>): CookieConsentConfig {
  return {

    language: {
      default: __i18n_current,
      translations: { en, pl },
    },

    mode: 'opt-in',
    autoShow: true,
    disablePageInteraction: true,

    guiOptions: {
      consentModal: {
        layout: 'box wide',
        position: 'middle center',
      },
      preferencesModal: {
        layout: 'box',
      },
    },

    categories: {
      necessary: {
        enabled: true,
        readOnly: true,
      },
      analytics: {
        enabled: false,
      },
      targeting: {
        enabled: false,
      },
      functionality: {
        enabled: false,
      },
    },

    onConsent({ cookie }) {
      pushUpdatedConsent(cookie);
    },

    onChange({ cookie }) {
      pushUpdatedConsent(cookie);
    },

    ...config,
  };
}


export const CookieConsent = {
  install(app: App, config: Partial<CookieConsentConfig> = {}) {
    app.config.globalProperties.$cookies = Cookies;
    app.config.globalProperties.$cookies.run(buildDefaultSettings(config));
  },
};
