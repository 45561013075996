<script setup lang="ts">
  import { useBreakpoints } from '$storefront';
  import type { PropType } from 'vue';
  import type { Photo } from '@plenny/picture';

  const breakpoints = useBreakpoints();

  defineProps({
    content: { type: String, required: false },
    name: { type: String, required: false },
    link: { type: String, required: false },
    photos: { type: Array as PropType<Photo[]>, required: true },
  });
</script>
<template>
  <div class="about-wrapper">
    <div class="about-header">
      <MediaPicture class="about-logo" :photo="photos" type="icon" />
      <SfButton v-if="!breakpoints.xl" class="btn-custom" :class="{ 'hide': breakpoints.xl }" small transparent :href="link" :aria-hidden="breakpoints.xl">
        <template #default>
          {{ $t('Zobacz więcej') }}
        </template>
        <template #after>
          <SfIconChevronRight />
        </template>
      </SfButton>
    </div>
    <div v-if="content" class="about-info" v-html="content" />
    <div v-if="breakpoints.xl" class="button-wrapper">
      <SfButton :href="link">
        {{ name }}
      </SfButton>
    </div>
  </div>
</template>
<style scoped lang="scss">
  .about-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    row-gap: 3rem;
    padding: 3rem 4.5rem;
    background-color: var(--color-white);
    border-radius: 0.375rem;
    flex-grow: 1;
    line-height: 1.5;

    .button-wrapper {
      text-align: end;
    }

    .btn-custom.hide {
      display: none;
    }

    .about-header {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      justify-content: space-between;
      align-items: center;

      :deep(.about-logo) {
        max-width: 50%;

        img {
          height: 70px;
        }
      }
    }
  }

  @media (max-width: 1400px) {
    .about-wrapper {
      padding: 2rem;
    }
  }

  @media(max-width: 992px) {
    .about-wrapper {
      padding: 1rem;
      gap: 1rem;
    }
  }
</style>
