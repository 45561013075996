<script lang="ts" setup>
  import { useBreakpoints, useCustomerOrderAbility, useCustomerAccepted } from '$storefront';
  import { route, $post, useShared, $delete } from '@plenny/visitor';
  import { computed } from 'vue';
  import { trans } from '@plenny/translator';

  const props = defineProps({
    product: {
      type: Object,
      required: true,
    },
    header: {
      type: String,
      required: false,
      default: 'h2',
    },
    horizontal: {
      type: Boolean,
    },
  });

  const canCustomerOrder = useCustomerOrderAbility();
  const isCustomerAccepted = useCustomerAccepted();
  const breakpoints = useBreakpoints();
  const shared = useShared();

  const target = computed(() => {
    return route('web.product.show', { slug: props.product.slug });
  });

  const isCompared = computed(() => {
    if (props.product.variant_id && shared.value.comparator) {
      return shared.value.comparator.map((c) => c.variant_id).includes(props.product.variant_id);
    } else {
      return false;
    }
  });

  const compareTitle = computed(() => {
    return isCompared.value ? trans('Usuń z porównania') : trans('Dodaj do porównania');
  });

  const hasListAttributes = computed(() => {
    return props.product.list_attributes && props.product.list_attributes.length > 0;
  });

  function toggleComparator() {
    if (isCompared.value) {
      $delete(route('api.v1.web.comparator.destroy', { 'variant_id': props.product.variant_id }));
    } else {
      $post(route('api.v1.web.comparator.store', { 'variant_id': props.product.variant_id }));
    }
  }
</script>
<template>
  <div :class="{ 'product--horizontal': horizontal }" class="product" @click="$redirect(target)">
    <div class="product__wrapper">
      <div class="product__picture-wrapper">
        <MediaPicture :photo="product.photo" sizes="740x560 2x, 370x280, 1000x738 2x max 576, 500x369 max 576" />
      </div>
      <div class="product__content">
        <div class="product__info">
          <header>
            <component :is="header" class="product__title">
              <VisitorLink :href="target">
                {{ product.title }}
              </VisitorLink>
            </component>
          </header>
          <div class="product__icons">
            <slot name="icons">
              <SfWishlistButton :variant="product.variant_id" />
              <SfButton :class="{'compared': isCompared}" :title="compareTitle" :variant="product.variant_id" class="product-card__compare" square tabindex="0" transparent @click.stop="toggleComparator">
                <IconScale />
              </SfButton>
            </slot>
          </div>
        </div>
        <div class="product__data">
          <dl v-if="hasListAttributes" v-show="horizontal" class="product__details">
            <div v-for="attribute in product.list_attributes" :key="attribute.name" class="product__details-column">
              <dt class="product__property">{{ attribute.name }}:</dt>
              <dd :title="attribute.value" class="product__value">{{ attribute.value }}</dd>
            </div>
          </dl>
          <div v-if="canCustomerOrder" class="product__add-item-wrapper">
            <div class="product__price">
              <SfProductPrice :price="product" />
              <SfProductOmnibus :price="product" />
            </div>
            <SfCartButton :product="product">
              <SfButton primary :square="!horizontal || horizontal && !breakpoints.lg">
                <template v-if="horizontal && breakpoints.lg" #default>
                  {{ $t('Dodaj do koszyka') }}
                </template>
                <template #after>
                  <IconCart />
                </template>
              </SfButton>
            </SfCartButton>
          </div>
          <div v-else class="product__bottom">
            <span v-if="isCustomerAccepted === undefined" class="product__bottom__guest" @click.stop="void 0">
              <VisitorLink :href="route('web.account.session.create')" rel="nofollow" class="product__bottom__guest__link">
                {{ $t('Zaloguj się, aby zobaczyć ceny.') }}
              </VisitorLink>
            </span>
            <span v-else class="product__bottom__guest">
              {{ $t('Twoje konto nie jest jeszcze aktywne.') }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="product__labels">
      <SfProductTag :visible="!!product.is_new" tag-type="novelty" icon>
        <template #icon>
          <IconBookmarkStar />
        </template>
        {{ $t('Nowość') }}
      </SfProductTag>
      <SfProductTag :visible="product.price_gross !== product.regular_gross" tag-type="discount" icon>
        {{ $t('Promocja') }}
      </SfProductTag>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .product {
    display: flex;
    position: relative;
    flex-grow: 1;
    color: #393836;
    border: 1px solid transparent;
    background-color: var(--color-white);
    transition-property: border;
    transition-duration: var(--transition-primary-duration);
    transition-timing-function: var(--transition-primary-timing-function);
    cursor: pointer;

    &__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      padding: 2rem 1.75rem;
      width: 100%;
    }

    &__picture-wrapper {
      width: 100%;
    }

    &__content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
    }

    &__title {
      width: 100%;
      font-size: 0.875rem;
      margin: 1rem 0 1.25rem;
      font-weight: var(--font-weight-regular);
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;

      a {
        color: #393836;
      }
    }

    &__icons {
      position: absolute;
      right: 1.75rem;
      top: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: auto;
      opacity: 1;
      --sf-btn-transparent-background: var(--color-white);
      --sf-btn-transparent-background-hover: var(--color-white);
      --sf-btn-transparent-background-active: var(--color-white);
      --sf-btn-transparent-background-focus: var(--color-white);

      :deep(.btn--square) {
        --sf-btn-font-size: 1.25rem;
        --sf-btn-font-size-icon-sm: 1.25rem;
        --sf-btn-padding-y: 0.375rem;
        --sf-btn-padding-y-sm: 0.375rem;
      }
    }

    &__labels {
      position: absolute;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      top: 2rem;
      left: 1.75rem;
    }

    &__bottom__guest {
      color: var(--secondary-color);
      font-size: 0.875rem;

      a {
        text-decoration: underline;
      }
    }

    &__add-item-wrapper {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      gap: 1rem;
      width: 100%;
    }

    &__price {
      display: flex;
      flex-direction: column;
      gap: 0.375rem;
    }

    &__data {
      display: flex;
      gap: 1rem;
      justify-content: space-between;
    }

    &__details {
      display: flex;
      flex-direction: column;
      font-size: 0.75rem;
      line-height: 1.5;
    }

    &__details-column {
      display: flex;
      gap: 0.2rem;
    }

    &__value {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }

    &--horizontal {
      --sf-product-price-current-font-size: 1.25rem;
      --sf-product-price-catalog-font-size: 0.875rem;
      --sf-product-price-per-font-size: 0.875rem;

      > .product__wrapper {
        flex-direction: row;
        align-items: flex-start;
        gap: 1rem;
        padding: 2rem;

        > .product__picture-wrapper {
          flex: 0 0 270px;
          width: unset;

          @media(min-width: 993px) and (max-width: 1400px) {
            flex: 0 0 170px;
          }
        }

        > .product__content {
          align-items: flex-end;
          gap: 1rem;
          height: 100%;

          > .product__info {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            gap: 1rem;
            width: 100%;

            @media(min-width: 993px) and (max-width: 1400px) {
              flex-direction: column;
            }

            > header > .product__title {
              margin: 0;
            }

            > .product__icons {
              position: static;
              flex-direction: row;
              gap: 1.25rem;
            }
          }

          > .product__data {
            flex-grow: 1;
            width: 100%;

            @media(min-width: 993px) and (max-width: 1400px) {
              flex-direction: column;
            }

            > .product__add-item-wrapper {
              flex-direction: column;
              justify-content: flex-end;
              width: auto;
              margin-left: auto;

              > :deep(.product__price) {
                align-items: flex-end;

                > .price,
                > .omnibus {
                  align-items: flex-end;
                  text-align: right;
                }
              }
            }
          }
        }
      }

      > .product__labels {
        top: 1rem;
        left: 1rem;
        flex-direction: row;
      }
    }

    @media(hover: hover) {
      &:hover {
        border-color: var(--secondary-border);
      }
    }
  }

  @media(max-width: 992px) {
    .product {
      &__wrapper {
        padding: 1rem;
      }

      &__icons {
        right: 0.5rem;
        top: 0.5rem;

        :deep(.btn--square) {
          --sf-btn-padding-y: 0.75rem;
          --sf-btn-padding-y-sm: 0.75rem;
        }
      }

      &__labels {
        left: 1rem;
        top: 1rem;
      }

      &__title {
        margin: 1rem 0;
      }

      &--horizontal > .product__wrapper {
        padding: 1rem;

        > .product__picture-wrapper {
          flex: 0 0 170px;
        }

        @media(max-width: 576px) {
          flex-direction: column;

          > .product__picture-wrapper {
            flex: auto;
            width: 100%;
          }
        }

        > .product__content {
          align-items: center;

          > .product__info > .product__icons {
            position: absolute;
            flex-direction: column;
            gap: 0;
          }

          > .product__data {
            flex-direction: column;
            width: 100%;

            > .product__add-item-wrapper {
              flex-direction: row;
              justify-content: space-between;
              width: 100%;

              > :deep(.product__price) {
                align-items: flex-start;

                > .price,
                > .omnibus {
                  align-items: flex-start;
                  text-align: left;
                }
              }
            }
          }
        }
      }
    }
  }
</style>
