<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="154" height="46.715" viewBox="0 0 154 46.715">
    <path d="M0,36.88H14.625L26.614,49.5l12.1-12.619H49.67V65.042H39.4V48.18L25.236,62.346Z" transform="translate(0 -31.474)" fill="#120f0d" />
    <path d="M24.65,87.75l7.571,7.571v13.135H24.65Z" transform="translate(-21.037 -74.888)" fill="#120f0d" />
    <path d="M358.43,46.66V73.388h10.325V57.213Z" transform="translate(-305.893 -39.821)" fill="#120f0d" />
    <path d="M376.023,36.871l7.886,7.886h7.255l1.714,1.713a4.045,4.045,0,0,1,0,5.721l-1.4,1.4H385.6l24.591,24.591h10.854L398.282,55.418a10.317,10.317,0,0,0,4.982-8.855,9.113,9.113,0,0,0-7.571-9.406L376.02,36.87Z" transform="translate(-320.905 -31.466)" fill="#120f0d" />
    <path d="M791.22,36.88V44.8h12.732l8-7.714Z" transform="translate(-675.246 -31.474)" fill="#120f0d" />
    <path d="M792.748,38.84H807.2l-19.9,19.9h19.558v7.8H765.39Z" transform="translate(-653.202 -33.147)" fill="#120f0d" />
    <path d="M597.093,40.7,590.2,47.595a6.633,6.633,0,1,1-2.559.031l-6.9-6.9a15.7,15.7,0,1,0,16.352-.025Z" transform="translate(-489.208 -34.734)" fill="#120f0d" />
    <path d="M625.131,2.574l7.84,7.84V0a18.775,18.775,0,0,0-4.522.717h0a10.148,10.148,0,0,0-3.32,1.857Z" transform="translate(-533.497)" fill="#0c8fde" />
    <path d="M686.5,2.574l-7.84,7.84V0a18.776,18.776,0,0,1,4.522.717h0a10.148,10.148,0,0,1,3.32,1.857Z" transform="translate(-579.185)" fill="#6bc8f9" />
  </svg>
</template>