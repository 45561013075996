<script setup>
  import { $patch, route } from '@plenny/visitor';
  import { ref, computed } from 'vue';
  import { trans } from '@plenny/translator';

  const signUpEmail = ref(false);

  function onSubmit(data) {
    return $patch(route('api.v1.web.newsletter.update'), data).then(() => {
      signUpEmail.value = true;
    });
  }

  const privacyPolicy = computed(() => {
    return trans('Akceptuję <a href=":url" target="_blank">politykę prywatności</a> sklepu.', { url: route('web.privacy_policy.index') });
  });
</script>
<template>
  <div class="newsletter">
    <SfHeading large>
      {{ $t('Newsletter') }}
    </SfHeading>
    <template v-if="!signUpEmail">
      <VisitorForm v-slot="{ submitting }" @submit="onSubmit">
        <p class="newsletter__text">
          {{ $t('Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,') }}
        </p>
        <div class="newsletter__content">
          <SfFormInput :label="$t('E-mail')" name="email" required />
          <div class="newsletter__consent">
            <SfFormSwitch name="acceptance_rodo" required>
              <span v-html="privacyPolicy" class="newsletter__policy" />
            </SfFormSwitch>
          </div>
          <SfButton primary :loading="submitting" type="submit">
            {{ $t('Zapisz się') }}
          </SfButton>
        </div>
      </VisitorForm>
    </template>
    <template v-else>
      <p class="subscribed">{{ $t('Dziękujemy za zapisanie się do naszego newslettera') }}</p>
    </template>
  </div>
</template>
<style lang="scss">
  .newsletter {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 2rem;
    color: var(--color-white);

    &__content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 1rem;
    }

    &__consent {
      width: 100%;
      --sf-checkbox-label-font-size: 0.75rem;
      --sf-switch-label-color: var(--primary-foreground);

      a {
        color: var(--primary-foreground);
        text-decoration: underline;

        &:hover {
          color: var(--primary-color);
        }
      }
    }

    .subscribed {
      font-weight: var(--font-weight-bold);
      font-size: 1.25rem;
      text-align: center;
    }
  }

  @media(max-width: 992px) {
    .newsletter {
      gap: 1rem;

      &__text {
        font-size: 0.875rem;
      }
    }
  }
</style>

