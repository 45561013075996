<script setup lang="ts">
  import { ref, type PropType } from 'vue';
  import type { Section } from '$types';

  defineProps({
    product: { type: Object, required: true },
    sections: { type: Array as PropType<Section[]>, required: false, default: [] },
    recommended: { type: Array, required: false, default: [] },
    reviews: { type: Object, required: true },
    reviewAvailable: { type: Boolean, required: true },
    reviewsSummary: { type: Object, required: true },
  });

  const parameters = ref({
    navigation: true,
    breakpoints: {
      1: {
        slidesPerView: 1.5,
        slidesOffsetBefore: 16,
      },
      576: {
        slidesPerView: 2,
        slidesOffsetBefore: 16,
      },
      992: {
        slidesPerView: 3,
        slidesOffsetBefore: 0,
      },
      1400: {
        slidesPerView: 4,
      },
    },
  });
</script>
<template>
  <div class="container description-wrapper" v-if="sections.length > 0 || product.list_attributes.length > 0 || product.reviews_average > 0">
    <ShopProductViewDescriptionSection v-if="sections.length > 0" :title="$t('Opis')">
      <SectionRenderer :sections />
    </ShopProductViewDescriptionSection>

    <ShopProductViewDescriptionSection v-if="product.list_attributes.length > 0" :title="$t('Specyfikacja')">
      <ShopProductViewDescriptionSpecification :product />
    </ShopProductViewDescriptionSection>

    <ShopProductViewDescriptionSection :title="$t('Opinie')">
      <ShopProductViewDescriptionReviews :reviews :product :reviewAvailable :reviewsSummary />
    </ShopProductViewDescriptionSection>

    <section v-if="recommended.length > 0" class="description-wrapper__products">
      <header class="description-wrapper__products-header">
        <h2 class="description-wrapper__products-heading">{{ $t('Sprawdź również') }}</h2>
      </header>
      <SfProductsCarousel :products="recommended" :parameters />
    </section>
  </div>
</template>
<style scoped lang="scss">
  .description-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
    border-top: 1px solid var(--secondary-border);

    &__products {
      margin-top: 5rem;
    }

    &__products-heading {
      padding: 3rem 5rem 2rem;
      border-top: 1px solid var(--secondary-border);
      font-size: 2rem;
      font-weight: var(--font-weight-bold);
      color: var(--primary-color-hover);
    }
  }

  @media(max-width: 992px) {
    .description-wrapper {
      margin-top: 3rem;

      &__products {
        padding: 0;
      }

      &__products-header {
        padding: 0 1rem;
      }

      &__products-heading {
        padding: 1rem 0;
        font-size: 1rem;
      }
    }
  }
</style>
