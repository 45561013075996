import type { Translation } from 'vanilla-cookieconsent';
import { route } from '@plenny/visitor';

export default function createPolishTranslation(): Translation {
  return {
    consentModal: {
      title: 'Ciasteczka',
      description: 'Używamy plików cookie na naszej stronie internetowej, aby zapewnić Ci jak najlepsze odczucia podczas korzystania z serwisu, dzięki zapamiętywaniu twoich preferencji podczas kolejnych wizyt na stronie. Klikając przycisk <b>Akceptuj wszystkie</b>, wyrażasz zgodę na używanie wszystkich plików ciasteczek. Możesz jednak odwiedzić <a href="javascript:void(0)" data-cc="show-preferencesModal" class="cc-link">ustawienia</a>, aby wyrazić kontrolowaną zgodę.',
      acceptAllBtn: 'Akceptuj wszystkie',
      acceptNecessaryBtn: 'Odrzuć wszystkie',
      showPreferencesBtn: 'Ustawienia',
    },
    preferencesModal: {
      title: 'Preferencje ciasteczek',
      acceptAllBtn: 'Akceptuj wszystkie',
      acceptNecessaryBtn: 'Odrzuć wszystkie',
      savePreferencesBtn: 'Zapisz ustawienia',
      closeIconLabel: 'Zamknij',
      sections: [
        {
          title: 'Informacje',
          description: 'Używamy plików cookie na naszej stronie internetowej, aby zapewnić Ci jak najbardziej odpowiednie wrażenia dzięki zapamiętywaniu twoich preferencji i powtarzaniu wizyt. Po więcej szczegółów udaj się do naszej <a class="cc-link" href="' + route('web.privacy_policy.index') + '">polityki prywatności</a>.',
        },
        {
          title: 'Wymagane ciasteczka',
          description: 'Te ciasteczka są wymagane do poprawnego funkcjonowania strony.',
          linkedCategory: 'necessary',
        },
        {
          title: 'Analityczne',
          description: 'Statystyczne pliki cookie pomagają nam zrozumieć, w jaki sposób różni użytkownicy zachowują się na stronie, gromadząc i zgłaszając anonimowe informacje.',
          linkedCategory: 'analytics',
        },
        {
          title: 'Reklamowe i targetujące',
          description: 'Sprawdzamy co przeglądasz i kupujesz na naszej stronie, aby lepiej dopasować do Ciebie reklamy. Dzięki temu zobaczysz tylko te reklamy, które mogą Ci się przydać i być dla Ciebie interesujące.',
          linkedCategory: 'targeting',
        },
        {
          title: 'Funkcjonalne',
          description: 'Te pliki cookie służą do bardziej spersonalizowanego korzystania z naszej strony internetowej i do zapamiętywania wyborów dokonywanych podczas korzystania z naszej strony internetowej. Na przykład możemy używać funkcjonalnych plików cookie do zapamiętywania preferencji językowych lub zapamiętywania danych logowania.',
          linkedCategory: 'functionality',
        },
        {
          title: 'Więcej informacji',
          description: 'W przypadku jakichkolwiek pytań dotyczących naszej polityki dotyczącej plików cookie i wyborów <a class="cc-link" href="' + route('web.contact.index') + '">prosimy o kontakt</a>.',
        },
      ],
    },
  };
}
