<script setup>
  import { useBreakpoints } from '$storefront';

  const breakpoints = useBreakpoints();

  defineOptions({
    inheritAttrs: false,
  });

  defineProps({
    items: Array,
  });
</script>
<template>
  <div class="wrapper hero-container">
    <SfBanerCarousel :items class="hero-container__baner" />
  </div>
</template>
<style lang="scss">
  .hero-container {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 600px;

    --sf-carousel-button-color: var(--secondary-foreground);
    --sf-carousel-button-color-hover: var(--sf-carousel-button-color);
    --sf-carousel-button-background: var(--secondary-background);
    --sf-carousel-button-background-hover: var(--secondary-background);
    --sf-carousel-button-border-width: 1px;
    --sf-carousel-button-border: var(--secondary-border);
    --sf-carousel-button-border-hover: var(--secondary-border-hover);

    &__baner {
      --sf-carousel-slide-padding-x: 0;
      --sf-carousel-slide-padding-y: 0;
      --sf-carousel-button-space: 0.625rem;
    }

    .baner-carousel {
      width: 100%;
      z-index: 1;

      &__container {
        height: 100%;
        width: 100%;
      }

      &__slide {
        overflow: hidden;
      }

      &__link {
        width: 100%;

        picture img {
          object-fit: cover;
          aspect-ratio: auto;
          height: 100%;
        }
      }
    }
  }

  @media(max-width: 992px) {
    .hero-container {
      max-height: 50vw;

      &__baner {
        padding: 0;
      }

      .baner-carousel {
        width: 100%
      }
    }
  }
</style>
