<script setup>
  import { ref } from 'vue';
  import { useSession, route } from '@plenny/visitor';

  const session = useSession();
  const showCategories = ref(false);
  const showMenu = ref(false);
</script>

<template>
  <nav class="navigation">
    <ul class="navigation__menu">
      <li class="navigation__item">
        <button class="navigation__element" @click="showMenu = true">
          <IconHamburger class="navigation__icon navigation__icon--default" />
          <span>{{ $t('Menu') }}</span>
        </button>
      </li>
      <li class="navigation__item">
        <button class="navigation__element" @click="showCategories = true">
          <IconGrid class="navigation__icon navigation__icon--default" />
          <span>{{ $t('Kategorie') }}</span>
        </button>
      </li>
      <li class="navigation__item">
        <VisitorLink :href="route('web.product.index')" class="navigation__element">
          <IconShoppingCart class="navigation__icon" />
          {{ $t('Sklep') }}
        </VisitorLink>
      </li>
      <li class="navigation__item">
        <!-- when user is not logged in those two routes redirect to the same route, so two menu item gets .active class - in order to reset .active styles when needed we can add an extra unauthenticated-whisthist class -->
        <VisitorLink :class="{'navigation__element--unauthenticated': !session.is_authenticated }" :href="session.is_authenticated ? route('web.account.wishlist.index') : route('web.account.session.create')" class="navigation__element" explicit>
          <IconHeart class="navigation__icon" />
          {{ $t('Ulubione') }}
        </VisitorLink>
      </li>
      <li class="navigation__item">
        <VisitorLink :href="session.is_authenticated ? route('web.account.show') : route('web.account.session.create')" class="navigation__element" explicit>
          <IconPerson class="navigation__icon" />
          {{ $t('Konto') }}
        </VisitorLink>
      </li>
    </ul>
  </nav>
  <SfDrawerCategories v-model:open="showCategories" />
  <MenuDrawer v-model:open="showMenu" />
</template>

<style lang="scss" scoped>
  .navigation {
    background-color: var(--color-grey-light);
    border-top: 1px solid var(--secondary-border);
    height: 58px;

    &__menu {
      display: flex;
      justify-content: space-evenly;
      height: 100%;
      list-style: none;
      font-size: 0.75rem;
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20%;
    }

    &__element {
      background-color: transparent;
      border: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.25rem;
      width: 100%;
      height: 100%;
      color: var(--color-default);
      font-size: 0.75rem;

      &.active {
        color: var(--primary-color);
      }

      &--unauthenticated {
        &.active {
          color: var(--color-black);
        }
      }
    }

    &__icon {
      font-size: 1.25rem;
    }
  }
</style>
