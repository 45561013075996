<script lang="ts" setup>
  import { onMounted, ref, type Ref, onBeforeUpdate, onUpdated, onBeforeUnmount, type PropType } from 'vue';
  import type { NavButtons } from '$types';
  import Swiper from 'swiper';
  import { Navigation } from 'swiper/modules';
  import 'swiper/css';
  import 'swiper/css/navigation';
  import type { Photo } from '@plenny/picture';

  type category = {
    photo: Photo[],
    title: string
  }

  defineProps({
    categories: { type: Array as PropType<category[]>, required: false, default: [] },
  });

  const htmlElement = ref() as Ref<HTMLElement>;
  const htmlNavigation = ref() as Ref<NavButtons>;
  const swiper = ref() as Ref<Swiper>;

  function initializeSwipers() {
    swiper.value = new Swiper(htmlElement.value, {
      modules: [Navigation],
      breakpoints: {
        1: {
          slidesOffsetBefore: 16,
          spaceBetween: 20,
          slidesPerView: 1.5,
        },
        576: {
          slidesPerView: 2.5,
          slidesOffsetBefore: 16,
          spaceBetween: 20,
        },
        992: {
          spaceBetween: 32,
          slidesOffsetBefore: 0,
        },
        1200: {
          slidesPerView: 3,
        },
        1400: {
          slidesPerView: 4,
        },
      },
      navigation: {
        nextEl: htmlNavigation.value.next.htmlElement,
        prevEl: htmlNavigation.value.prev.htmlElement,
      },
    });
  }

  function destroySwipers() {
    swiper.value?.destroy();
  }

  onMounted(() => {
    initializeSwipers();
  });

  onBeforeUpdate(() => {
    destroySwipers();
  });

  onUpdated(() => {
    initializeSwipers();
  });

  onBeforeUnmount(() => {
    destroySwipers();
  });
</script>
<template>
  <div class="items-carousel categories-carousel">
    <div ref="htmlElement" class="swiper items-carousel__container categories-carousel__container">
      <div class="swiper-wrapper">
        <div v-for="category in categories" class="swiper-slide items-carousel__slide categories-carousel__article">
          <VisitorLink :href="route('web.product.category', { category })" class="categories-carousel__link">
            <MediaPicture :photo="category.photo" sizes="100x100 2x, 50x50" />
            <span>{{ category.title }}</span>
          </VisitorLink>
        </div>
      </div>
    </div>
    <SfCarouselButtons ref="htmlNavigation" />
  </div>
</template>
<style lang="scss" scoped>
  .categories-carousel {
    padding: var(--sf-products-carousel-padding);

    &__link {
      display: flex;
      align-items: center;
      gap: 1rem;

      :deep(img) {
        width: 50px;
        aspect-ratio: 1;
      }

      span {
        font-weight: var(--font-weight-bold);
        word-break: break-word;
        transition-property: color;
        transition-duration: var(--transition-primary-duration);
        transition-timing-function: var(--transition-primary-timing-function)
      }
    }
  }
</style>

