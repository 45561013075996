<script setup lang="ts">
  import { ref } from 'vue';
  import { useCustomerOrderAbility, useCustomerAccepted } from '$storefront';
  import { route } from '@plenny/visitor';

  const variant = defineModel('variant', { type: Object, required: true });

  defineProps({
    product: { type: Object, required: true },
  });

  const canCustomerOrder = useCustomerOrderAbility();
  const isCustomerAccepted = useCustomerAccepted();
  const successModalVisible = ref(false);

  const showSuccessModal = () => {
    successModalVisible.value = true;
  };
</script>
<template>
  <div class="picker">
    <div class="picker__options">
      <SfProductStock :variant="variant" v-if="canCustomerOrder" />
      <SfVariantPicker series :product="product" v-model:variant="variant" />
    </div>
    <div v-if="canCustomerOrder" class="picker__product">
      <SfProductPrice :price="variant" presentation />
      <SfProductOmnibus :price="variant" />
      <SfProductPoints :variant="variant" />
      <SfProductMultipackUnitPrice :price="variant" />
      <SfProductUnitPrice :price="variant" />
      <SfCartStoreButton :variant="variant" @added="showSuccessModal" class="picker__store" />
      <CartButtonSuccessModal :open="successModalVisible" :product="product" :variant="variant" @update:open="successModalVisible = $event" />
    </div>
    <div class="picker__guest" v-else>
      <VisitorLink v-if="isCustomerAccepted === undefined" :href="route('web.account.session.create')" rel="nofollow">
        {{ $t('Zaloguj się, aby zobaczyć ceny.') }}
      </VisitorLink>
      <span v-else>
        {{ $t('Twoje konto nie jest jeszcze aktywne.') }}
      </span>
    </div>
  </div>
</template>
<style scoped lang="scss">
  .picker {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: end;
    gap: 5rem;

    &__product {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
    }

    &__store :deep(.cart-store-button__button) {
      flex-direction: column;
    }

    &__guest {
      color: var(--secondary-color);
      font-weight: var(--font-weight-bold);
      font-size: 1rem;

      a {
        text-decoration: underline;
      }
    }

    &__options {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      --gap-card: 1rem;
    }
  }

  @media(max-width: 1400px) {
    .picker {
      gap: 2rem;
    }
  }

  @media(max-width: 768px) {
    .picker {
      grid-template-columns: 1fr;
    }
  }
</style>
