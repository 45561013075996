<script setup lang="ts">
  import { computed, type PropType } from 'vue';
  import IconBookmarkStar from '../../../Components/icons/IconBookmarkStar.vue';

  interface Product {
    is_new: boolean,
  }

  interface Variant {
    price_gross: string,
    regular_gross: string,
  }

  const props = defineProps({
    product: { type: Object as PropType<Product>, required: true },
    variant: { type: Object as PropType<Variant>, required: true },
    icon: { type: Boolean, required: false, default: false },
  });

  const isNew = computed(() => {
    return props.product.is_new;
  });

  const isPromo = computed(() => {
    return props.variant.price_gross !== props.variant.regular_gross;
  });
</script>
<template>
  <div v-if="isNew || isPromo" class="product-tags-wrapper">
    <SfProductTag :visible="isNew" tag-type="novelty" :icon class="product-tags-wrapper__tag">
      <template #icon>
        <IconBookmarkStar />
      </template>
      {{ $t('Nowość') }}
    </SfProductTag>
    <SfProductTag :visible="isPromo" tag-type="discount" :icon class="product-tags-wrapper__tag">
      {{ $t('Promocja') }}
    </SfProductTag>
  </div>
</template>
<style scoped lang="scss">
  .product-tags-wrapper {
    display: flex;
    gap: 1rem;

    &__tag {
      flex-grow: 1;
      justify-content: center;
      padding: 0.5rem;
      font-weight: var(--font-weight-bold);
    }
  }
</style>
