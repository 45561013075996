<script setup>
  import { computed } from 'vue';
  import { route } from '@plenny/visitor';

  const props = defineProps({
    parent: { type: Object, required: false },
    category: { type: Object, required: false },
    categories: Array,
  });

  const title = computed(() => {
    if (props.parent) {
      return props.parent.title;
    } else if (props.category) {
      if (props.category._rgt === (props.category._lft + 1)) {
        return null;
      } else {
        return props.category.title;
      }
    } else {
      return null;
    }
  });

  const back = computed(() => {
    if (props.parent) {
      return route('web.blog.category.show', { category: props.parent });
    } else if (props.category) {
      return route('web.blog.post.index');
    } else {
      return null;
    }
  });
</script>
<template>
  <div class="filters-blog">
    <h2 class="filters-blog__heading">
      {{ $t('Kategorie') }}
    </h2>
    <div class="filters-blog__content">
      <VisitorLink v-if="back" :href="back" class="filters-blog__back">
        <SfIconChevronLeft />
        {{ $t('Wróć do') + ':' }}&nbsp;{{ parent?.title || $t('Wszystkie') }}
      </VisitorLink>
      <ul>
        <li v-for="category in categories">
          <VisitorLink :href="route('web.blog.category.show', { category })">
            <IconSignet class="filters-blog__category-icon" />
            {{ category.title }}
          </VisitorLink>
        </li>
      </ul>
    </div>
  </div>
</template>
<style scoped lang="scss">
  .filters-blog {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__back {
      display: inline-flex;
      gap: 0.375rem;
      align-items: center;
      padding: 1rem 0;
      font-weight: var(--font-weight-bold);
      color: #021E2D;

      svg {
        font-size: 0.875rem;
      }
    }

    &__category-icon {
      width: 1.25rem;
      height: 1.25rem;
      filter: grayscale(1);
      transition-property: filter;
      transition-timing-function: var(--transition-primary-timing-function);
      transition-duration: var(--transition-primary-duration);
    }

    &__heading {
      font-size: 1.25rem;
      color: #021E2D;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      list-style: none;

      a {
        display: flex;
        gap: 0.5rem;
        padding: 0.5rem 0;
        color: #393836;

        &.active {
          font-weight: var(--font-weight-bold);
        }

        &:hover,
        &.active {
          .filters-blog__category-icon {
            filter: grayscale(0);
          }
        }
      }
    }
  }

  @media(max-width: 992px) {
    .filters-blog {

      &__category-icon {
        width: 1rem;
        height: 1rem;
      }

      &__heading {
        font-size: 0.875rem;
      }

      &__back {
        font-size: 0.875rem;
        padding: 0.75rem 0;

        svg {
          font-size: 0.75rem;
        }
      }

      ul {
        gap: 0;

        a {
          padding: 0.75rem 0;
          font-size: 0.875rem;
        }
      }
    }
  }
</style>
