<script setup>
  import { computed } from 'vue';

  const props = defineProps({
    item: Object,
    depth: Number,
    index: String,
    category: Boolean,
  });

  const component = computed(() => {
    if (props.item.url) {
      return 'VisitorLink';
    } else {
      return 'span';
    }
  });

  const specific = computed(() => {
    if (props.item.url) {
      return {
        href: props.item.url || undefined,
        rel: props.item.rel || undefined,
        target: props.item.target || undefined,
        explicit: props.item.explicit,
      };
    } else {
      return {
        ['data-cc']: props.item.dataCc || undefined,
      };
    }
  });
</script>
<template>
  <component :is="component" v-bind="specific" tabindex="0">
    {{ item.name }}
  </component>
</template>
