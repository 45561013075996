<script setup>
  import { ref, computed } from 'vue';
  import { useCart } from '$storefront';

  const show = ref(false);
  const { cart } = useCart();
  const count = computed(() => cart.value?.items?.length || 0);
  const total = computed(() => cart.value?.total_gross || 0);
  const currency = computed(() => cart.value?.currency_code);
  const decimals = computed(() => cart.value?.currency_decimals);
</script>
<template>
  <div class="cart-value" @click="show = true" tabindex="0" @keydown.enter="show = true">
    <div class="cart-value__icon-wrapper">
      <IconCart class="cart-value__icon" />
      <span v-if="count > 0" class="cart-value__count" :aria-label="$t('Ilośc produktów w koszyku')">
        {{ count }}
      </span>
    </div>
    <div class="cart-value__money-wrapper">
      <SfMoney :code="currency" :decimals="decimals" :value="total" class="cart-value__money" />
      <span class="cart-value__gross">{{ $t('brutto') }}</span>
    </div>
  </div>
  <SfDrawerCart v-model:open="show" />
</template>
<style lang="scss" scoped>
  .cart-value {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    column-gap: 1.25rem;
    font-size: 1.5rem;
    white-space: nowrap;
    height: 100%;
    text-decoration: none;
    cursor: pointer;
    transition-property: color;
    transition-duration: var(--transition-primary-duration);
    transition-timing-function: var(--transition-primary-timing-function);

    &:hover {
      color: var(--primary-color);
    }

    &__icon-wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__count {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 1.25rem;
      font-size: 0.75rem;
      line-height: 1;
      padding: 0.25rem 0.375rem;
      border-radius: 50%;
      background-color: #B2DBF3;
      transform: translate(50%, -50%);
    }

    &__money-wrapper {
      display: flex;
      flex-direction: column;
    }

    &__money {
      font-size: 1rem;
      font-weight: var(--font-weight-bold);
    }

    &__gross {
      font-size: 0.75rem;
    }
  }

  @media(max-width: 768px) {
    .cart-value {
      gap: 0.375rem;
      font-size: 1.375rem;

      &__money {
        font-size: 0.875rem;
      }

      &__count {
        right: unset;
        left: 0;
        transform: translate(-50%, -50%);
      }
    }
  }
</style>
